import Navigation from "../Components/Navigation/Navigation";
import InWork from "../Components/InWork/inWork";

function Demos() {
    return (
        <div>
            <Navigation />
            <InWork />
        </div>
    )
}

export default Demos;