import './inWorkStyle.css';

function InWork() {
    return (
        <div className='infoBlock'>
            <p className='mainText'>Данная страница находится в разработке</p>
        </div>
    );
}

export default InWork;